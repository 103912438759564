import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      profilePicJamieSowder: file(
        relativePath: { eq: "profilepics/profile-pic-jamie-sowder-64x64.png" }
      ) {
        ...fluidImage
      }
      profilePicTonyNuzzi: file(
        relativePath: { eq: "profilepics/profile-pic-tony-nuzzi-64x64.png" }
      ) {
        ...fluidImage
      }
    }
  `);

  return (
    <div className="py-12 bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div id="Team" className="lg:mx-auto">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Team
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Analytics geeks
          </h3>
        </div>
        <div className="mt-10 mx-auto">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li className="pb-8 md:pb-0">
              <div className="flex">
                <div className="">
                  <div className="h-20 rounded-full">
                    <Img
                      className="h-20 w-20 rounded-full"
                      fluid={data.profilePicJamieSowder.childImageSharp.fluid}
                    />
                  </div>
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Jamie Sowder
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Founder and Head of Analytics.
                  </p>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Formerly PayPal, eBay, Hack Reactor, The Knot.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="flex">
                <div className="">
                  <div className="">
                    <Img
                      className="h-20 w-20 rounded-full"
                      fluid={data.profilePicTonyNuzzi.childImageSharp.fluid}
                    />
                  </div>
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Tony Nuzzi
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Advisor and Technical Architect.
                  </p>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Formerly PayPal, eBay, IBM, The Knot.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Team;